import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import '../App.css';
import logo from '../assets/logo.jpg';
import Clock from '../components/Clock';
import Calendar from '../components/Calendar';
import ChangeCalendar from '../components/ChangeCalander';
import { useNavigate } from 'react-router-dom'; 
import Kash from '../pages/Kash';
const Homepage = () => {
  let navigate = useNavigate();
  const navigateToKash = () => {
    navigate('/Kash'); 
  };
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content="This is the Time and Weather portal for Kurdistan" />
        <title>Kurdistan-Time and Weather portal</title>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossorigin="anonymous" />
        <link href="https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Coming+Soon&family=Permanent+Marker&display=swap" rel="stylesheet" />
        <script src="https://kit.fontawesome.com/2a15033da7.js" crossorigin="anonymous"></script>
      </Helmet>

      <div className="hero-section">
        <Container fluid>
          <Row>
            <Col md={6} className="logo-section d-flex justify-content-center align-items-center">
              <img src={logo} alt="Logo" className='img-fluid'/>
            </Col>
            <Col md={6} className="yellow-section d-flex flex-column">
              <Row className="w-100 p-3 navbar">
                <Col className="d-flex justify-content-evenly align-items-center">
                  <a href="#home" className="nav-link px-2">Home</a>
                  <a href="#about" className="nav-link px-2">About Us</a>
                  <a href="#services" className="nav-link px-2">Our Services</a>
                  <a href="#contact" className="nav-link px-2">Contact</a>
                </Col>
              </Row>

              <Container className="h-100 d-flex flex-column justify-content-center align-items-center">
              <div className="portal margin text-size mb-4">
                Online Portal dedicated to <span className='time-link'>Time</span> and <button
                    className="weather-link"
                    onClick={navigateToKash}
                    style={{
                      fontWeight: '100',
                      color: 'black',
                      display: 'inline-block',
                      textDecoration: 'none',
                      border: 'none',
                      background: 'none',
                      padding: 0,
                      cursor: 'pointer'
                    }}>
                  Weather
                </button> in Kurdewar
              </div>
                <Row className="w-100 justify-content-center"> 
                  <Col xs={12} sm={6} md={6} lg={4} className="text-center" style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <div className="mb-2">
                      <Clock timezone="Asia/Tehran"/>
                    </div>
                    <div className="krmashan">
                      Kirmaşan
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={4} className="text-center" style={{ marginBottom: '20px', marginTop: '20px' }}>
                    <div>
                      <Calendar />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="hero-section">
        <Container fluid>
          <Row>
            
            <Col md={6} className="yellow-section d-flex flex-column">

              <Container className="h-100 d-flex flex-column justify-content-center align-items-center">
                <div className="portal margin text-size mb-4">
                </div>
                <Row className="w-100 justify-content-center"> 
                  <Col xs={12} sm={12} md={12} lg={12} className="text-center" style={{ marginBottom: '20px'}}>
                    <div>
                      <ChangeCalendar/>
                       {/* Calendar */}
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md={6} className="logo-section d-flex justify-content-center align-items-center">
              <img src={logo} alt="Logo" className='img-fluid'/>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Homepage;
