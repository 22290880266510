import React from 'react';
import Homepage from './pages/Homepage';
import Kash from './pages/Kash';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes> {/* Wrap Route components with Routes */}
        <Route path="/" element={<Homepage/>} />
        <Route path="/kash" element={<Kash/>} />
      </Routes>
    </Router>
  );
}

export default App;
