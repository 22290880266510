import React, { useState } from 'react';
import moment from 'jalali-moment';
import '../ChangeCalendar.css'; // Ensure this path is correct

const ChangeCalendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const kurdishMonths = [
    "Xakelêwe", "Gullan", "Cozerdan", "Pûşper", "Gelawêj",
    "Xermanan", "Rezber", "Xezellwer", "Sermawez", "Befiranbar", "Rêbendan", "Reşeme"
  ];

  const getGregorianMonthsForKurdishMonth = (kurdishMonth) => {
    const monthStartEndDates = {
      "Xakelêwe": ["March 21", "April 20"],
      "Gullan": ["April 21", "May 21"],
      "Cozerdan": ["May 22", "June 21"],
      "Pûşper": ["June 22", "July 22"],
      "Xermanan": ["July 23", "August 22"],
      "Gelawêj": ["August 23", "September 22"],
      "Rezber": ["September 23", "October 22"],
      "Xezellwer": ["October 23", "November 21"],
      "Sermawez": ["November 22", "December 21"],
      "Befiranbar": ["December 22", "January 20"],
      "Rêbendan": ["January 21", "February 19"],
      "Reşeme": ["February 20", "March 20"]
    };
    const dates = monthStartEndDates[kurdishMonth];
    if (!dates) return "Unknown Month";

    // Assuming the year doesn't change within a Kurdish month (which is true for these date ranges)
    const startMonth = dates[0].split(" ")[0];
    const endMonth = dates[1].split(" ")[0];
    return startMonth === endMonth ? startMonth : `${startMonth} - ${endMonth}`;
  };

  const navigateMonth = (direction) => {
    setCurrentDate(current => direction === 'next' ? moment(current).add(1, 'jMonth').toDate() : moment(current).subtract(1, 'jMonth').toDate());
  };

  const kurdishYear = moment(currentDate).jYear() + 1321;
  const monthName = kurdishMonths[moment(currentDate).jMonth()];
  const gregorianMonths = getGregorianMonthsForKurdishMonth(monthName);

  const daysInMonth = moment(currentDate).jDaysInMonth();
  const startDay = moment(currentDate).startOf('jMonth').day();
  const today = moment().format('YYYY-MM-DD');

  const daysArray = new Array(daysInMonth + startDay).fill(null).map((_, index) => index >= startDay ? index - startDay + 1 : null);

  return (
    <div className="calendar-container">
      <div className="calendar-header">
        <button className="calendar-nav-button" onClick={() => navigateMonth('prev')}>&lt;</button>
        <div>
          <div>{gregorianMonths}</div>
          <span>{`${monthName} ${kurdishYear}`}</span>
        </div>
        <button className="calendar-nav-button" onClick={() => navigateMonth('next')}>&gt;</button>
      </div>
      <div className="calendar">
        {daysArray.map((day, index) => (
          day ? (
            <div key={index} className={`day ${moment(currentDate).startOf('jMonth').add(day - 1, 'days').format('YYYY-MM-DD') === today ? 'today' : ''}`}>
              <div>{moment(currentDate).startOf('jMonth').add(day - 1, 'days').format('D')}</div>
              <div className="kurdish-day">
                {new Intl.NumberFormat('ar-EG').format(day)}
              </div>
            </div>
          ) : <div key={index} className="day-empty"></div>
        ))}
      </div>
    </div>
  );
};

export default ChangeCalendar;
