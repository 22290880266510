import React from 'react';
import moment from 'jalali-moment';
import '../App.css';

const Calendar = () => {
  const kurdishMonths = [
    "Xakelêwe", "Gulan", "Cozerdan", "Pûşper", "Gelawêj",
    "Xermanan", "Rezber", "Xezelwer", "Sermawez", "Befiranbar", "Rêbendan", "Reşeme"
  ];

  const getKurdishDate = (date) => {
    let newDate = moment();
    let year = newDate.jYear() + 1321;
    let month = kurdishMonths[newDate.jMonth()];
    let day = newDate.jDate();
    return `  ${day} ${month} ${year}`;
  };


  const displayDate = getKurdishDate(); 

  return (
    <div className="dateBox">
      {displayDate}
    </div>
  );
};

export default Calendar;