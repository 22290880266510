import React, { useEffect } from "react";
import axios from 'axios';

export default function Kash(props) {
    useEffect(() => {
        function handleResponse(response) {
            alert(`The weather in ${response.data.name} is ${response.data.main.temp}°C`);
        }

        let apiKey = "a0a183380df8741e35218ccc59e2fe87";
        let city = props.city;
        let apiUrl = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${apiKey}&units=metric`;

        axios.get(apiUrl).then(handleResponse).catch(error => console.error('There was an error!', error));
    }, [props.city]);

    return <h2>Hello from {props.city}</h2>;
}
