import React, { useEffect, useRef } from 'react';

const Clock = () => {
  const hourHandRef = useRef(null);
  const minuteHandRef = useRef(null);
  const secondHandRef = useRef(null);

  useEffect(() => {
    const setClock = () => {
      const timeZone = 'Asia/Tehran';
      const currentDate = new Date().toLocaleString("en-US", {timeZone: timeZone});
      const currentTime = new Date(currentDate);

      const secondsRatio = currentTime.getSeconds() / 60;
      const minutesRatio = (secondsRatio + currentTime.getMinutes()) / 60;
      const hoursRatio = (minutesRatio + currentTime.getHours()) / 12;

      setRotation(secondHandRef.current, secondsRatio);
      setRotation(minuteHandRef.current, minutesRatio);
      setRotation(hourHandRef.current, hoursRatio);
    };

    const setRotation = (element, rotationRatio) => {
      if (element) {
        element.style.setProperty('--rotation', rotationRatio * 360);
      }
    };

    setClock();
    const intervalId = setInterval(setClock, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="clock">
      <div className="hand hour" ref={hourHandRef}></div>
      <div className="hand minute" ref={minuteHandRef}></div>
      <div className="hand second" ref={secondHandRef}></div>
      {[...Array(12).keys()].map(number => (
        <div key={number} className={`number number${number + 1}`}>{number + 1}</div>
      ))}
    </div>
  );
};

export default Clock;
